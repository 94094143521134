#textprogress {
    margin-bottom: 20px;
}

#textview {
    background: #f0f0f0;
    border-radius: 10px;
    width: 50vw;

    margin-bottom: 10px;
    color: black;
    padding: 20px;

    display: inline-block;
    overflow: hidden;
    text-align: left;
}

#textinput {
    background: #f0f0f0;
    border-radius: 10px;
    width: 50vw;
    height: 2em;
}

.textbubble {
    color: black;
    padding: 10px;
    margin: 1px;
    border-radius: 3px;
    min-width: 0;
    min-height: 0;
}

.current {
    background: #baffad;
}

.space {
    height: 1em;
}